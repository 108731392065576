var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"default-table-headers":[],"form-scheme":_vm.formScheme,"form-default-values":_vm.formDefaultValues,"resource":_vm.resource,"item-class-fn":_vm.itemClassFn},on:{"inputChange":_vm.handleChange},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"grey--text small",attrs:{"title":item.agent}},[_vm._v(" "+_vm._s(item.agent && item.agent.length > 50 ? ((item.agent.substr(0, 50)) + "...") : (item.agent || ''))+" ")])])]}},{key:"item.sys",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.sys === true ? 'grey' : 'grey'}},[_vm._v(" "+_vm._s(item.sys === true ? 'mdi-check' : 'mdi-minus')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","loading":item.statusLoading},on:{"click":function($event){return _vm.checkProxyStatus(item)}}},[_c('v-icon',{attrs:{"color":item.status === true ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status === true ? 'mdi-check' : 'mdi-sync-alert')+" ")])],1)]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('proxies.create'),expression:"'proxies.create'"}],attrs:{"icon":"","loading":item.cloneLoading},on:{"click":function($event){return _vm.cloneProxy(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1),_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('proxies.auth'),expression:"'proxies.auth'"}],attrs:{"icon":""},on:{"click":function($event){return _vm.getCredentials(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-key-chain-variant ")])],1),((item.domains_providers || []).length > 0)?_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('Who is using this proxy?'),"icon":"eye","can":'proxies.list',"has":'domains_providers',"fn":_vm.getProvidersWithThisProxy}}):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }