const userAgentsRaw = `Mozilla/5.0 (Windows; U; Windows NT 5.1; en-US; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (X11; U; Linux i686 (x86_64); de; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 6.1; en-US; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Macintosh; U; PPC Mac OS X 10.6; fr; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (X11; Linux x86_64; rv:13.0a1) Gecko/20120212 Firefox/13.0a1
Mozilla/5.0 (Windows; U; Windows NT 6.2; pt-PT; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Windows NT 6.3; rv:33.0) Gecko/20120216 Firefox/33.0
Mozilla/5.0 (Windows; U; Windows NT 6.1; es-AR; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 6.1; de; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:88.0) Gecko/20210331 Firefox/88.0
Mozilla/5.0 (Windows; U; Windows NT 6.2; pt-BR; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 5.1; pt-BR; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729)
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03797) Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.14
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03796) Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.5 (build 02842) Firefox/3.6.27 (.NET CLR 3.5.30729) sputnik 2.0.1.41
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.20
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 GTB7.1
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.14
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 (.NET CLR 3.5.30729) sputnik 2.3.0.102
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 (.NET CLR 3.5.30729) sputnik 2.5.2.14
Mozilla/5.0 (Windows; U; Windows NT 6.0; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 GTB7.1 (.NET CLR 3.5.30729)
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729; .NET4.0E) sputnik 2.5.2.14
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03797) Firefox/3.6.27 sputnik 2.5.2.20
Mozilla/5.0 (Windows; U; Windows NT 6.0; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 (.NET CLR 3.5.30729) sputnik 2.5.2.22
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.0.90
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729) sputnik 2.5.0.148
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.1.2
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.0.150
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 RubarToolbar sputnik 2.5.0.142
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729)
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.32
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.4.0.60
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.42
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 (.NET CLR 2.0.50727; .NET CLR 3.0.4506.2152; .NET CLR 3.5.30729)
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729) sputnik 2.5.2.22
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03797) Firefox/3.6.27 (.NET CLR 3.5.30729)
Mozilla/5.0 (Windows; U; Windows NT 6.0; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729; .NET4.0C)
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.3.0.96
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.22
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.32
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.3.0.94
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03796) Firefox/3.6.27
Mozilla/5.0 (Windows; U; Windows NT 6.0; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.5 (build 02842) Firefox/3.6.27 (.NET CLR 3.5.30729) sputnik 2.5.0.148
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729; .NET4.0E)
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.6 (build 03402) Firefox/3.6.27 sputnik 2.1.0.18
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.4.0.48
Mozilla/5.0 (Windows; U; Windows NT 6.0; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 (.NET CLR 3.5.30729)
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 RubarToolbar2714 RubarToolbar sputnik 2.5.2.32
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.5.2.42
Mozilla/5.0 (Windows; U; Windows NT 5.1; en-US; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 (.NET CLR 3.5.30729)
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 BTRS35926 Firefox/3.6.27 sputnik 2.5.2.42
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 ( .NET CLR 3.5.30729) sputnik 2.5.2.32
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.3.0.96
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.4 (build 02647) Firefox/3.6.27 sputnik 2.5.0.150
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03789) Firefox/3.6.27 sputnik 2.4.0.60
Mozilla/5.0 (Windows; U; Windows NT 6.0; ru; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 sputnik 2.4.0.60
Mozilla/5.0 (Windows; U; Windows NT 5.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03791) AskTbPTV2/3.11.3.15590 Firefox/3.6.27 ( ) sputnik 2.5.2.32
Mozilla/5.0 (Windows NT 5.1; rv:13.0a1) Gecko/20120215 Firefox/13.0a1
Mozilla/5.0 (Windows; U; Windows NT 6.1; ru; rv:1.9.2.27) Gecko/20120216 MRA 5.7 (build 03797) Firefox/3.6.27
Mozilla/5.0 (X11; Linux x86_64; rv:60.7.7) Gecko/20210106 Firefox/60.7.7
Mozilla/5.0 (Windows; U; Windows NT 5.1; es-ES; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27 GTB7.1
Mozilla/5.0 (Linux i386; X11) Gecko/20212008 Firefox/19.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 11_0_3) Gecko/20211305 Firefox/22.0
Mozilla/5.0 (X11; Linux x86_64; rv:60.8.0) Gecko/20210225 Firefox/60.8.0
Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:12.0a2) Gecko/20120217 Firefox/12.0a2
Mozilla/5.0 (Windows NT 5.1; WOW64) Gecko/20211503 Firefox/25.0
Mozilla/5.0 (Linux i386; X11) Gecko/20211901 Firefox/14.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_0_3) Gecko/20211302 Firefox/19.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_6_3) Gecko/20212509 Firefox/15.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 11_3_0) Gecko/20212907 Firefox/24.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 11_4_4) Gecko/20211006 Firefox/22.0
Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:87.0) Gecko/20100101 Firefox/87.0;0AK_6cZdL3jaV1OWbUdUDZHNyxn4fx7I39STzlbj2cdC_20210512
Mozilla/5.0 (Windows; U; Windows NT 6.1; tr; rv:1.9.2.27) Gecko/20120216 Firefox/3.6.27
Mozilla/5.0 (Windows NT 10.0; WOW64; rv:60.0) Gecko/20210101 Firefox/87.0
Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:80.0) Gecko/20211023 Firefox/80.0
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.90 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.90 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.90 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.90 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.54 Safari/537.36
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.20211
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.20216
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.20218
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.20215
Mozilla/5.0 (Linux; Android 11; IN2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/45.09.4.5087
Mozilla/5.0 (Linux; Android 11; IN2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/45.09.4.5083
Mozilla/5.0 (Linux; Android 10; moto g play (2021)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.02.4.5152
Mozilla/5.0 (Linux; Android 11; IN2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.03.4.5155
Mozilla/5.0 (Linux; Android 10; CPH2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.03.4.5155
Mozilla/5.0 (Linux; Android 10; moto g power (2021)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.04.4.5157
Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.6211.3134 Safari/537.36 Edge/18.2021
Mozilla/5.0 (Linux; Android 10; RMX2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.05.4.5158
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.62 OneOutlook/1.2021.730.2
Mozilla/5.0 (Linux; Android 10; moto g power (2021)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.06.4.5160
Mozilla/5.0 (Linux; Android 10; moto g play (2021)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.63 Mobile Safari/537.36 EdgA/93.0.961.38
Mozilla/5.0 (Linux; Android 10; moto g power (2021)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.06.4.5161
Mozilla/5.0 (Linux; Android 11; CPH2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.06.4.5161
Mozilla/5.0 (Linux; Android 11; IN2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.40 Mobile Safari/537.36 EdgA/95.0.1020.21
Mozilla/5.0 (Linux; Android 11; CPH2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Mobile Safari/537.36 EdgA/93.0.961.78
Mozilla/5.0 (Linux; Android 11; moto g play (2021)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Mobile Safari/537.36 EdgA/93.0.961.80
Mozilla/5.0 (Linux; Android 11; CPH2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Mobile Safari/537.36 EdgA/93.0.961.80
Mozilla/5.0 (Linux; Android 11; IN2021) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Mobile Safari/537.36 EdgA/93.0.961.80
Mozilla/5.0 (Linux; Android 10; moto g power (2021)) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Mobile Safari/537.36 EdgA/95.0.1020.42
Mozilla/5.0 (Linux armv7l) AppleWebKit/602.1.28+ (KHTML, like Gecko) Version/9.1
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Safari/605.1.15
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0
Mozilla/5.0 (Linux armv7l) AppleWebKit/602.1.28+ (KHTML%2C like Gecko) Version/9.1
Mozilla/5.0 (Linux armv7l) AppleWebKit/602.1.28+ (KHTML%2C like Gecko) Version/9.1
Mozilla/5.0 (Linux armv7l) AppleWebKit/602.1.28+ (KHTML%2C like Gecko) Version/9.1`

const userAgents = userAgentsRaw.split('\n')
export default userAgents
